@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
/* Font imports */

@font-face {
	font-family: 'Chakra Petch';
	src: url('./assets/fonts/ChakraPetch-Regular.ttf');
}

@font-face {
	font-family: 'Chakra Petch';
	src: url('./assets/fonts/ChakraPetch-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Chakra Petch';
	src: url('./assets/fonts/ChakraPetch-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Chakra Petch';
	src: url('./assets/fonts/ChakraPetch-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Chakra Petch';
	src: url('./assets/fonts/ChakraPetch-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

/* CHANGE_BOILER */
@layer components {
	.btn-primary {
		@apply bg-orange-base text-white px-[1.5rem] py-3 rounded-[3px] mb-1 text-[14px] uppercase font-roboto transition-all w-full font-medium flex gap-2 justify-center cursor-pointer hover:scale-[1.03] duration-300 hover:bg-orange-highlight hover:shadow-lg disabled:bg-orange-light disabled:hover:bg-orange-light disabled:hover:scale-100 disabled:hover:shadow-none;
	}

	.input-primary {
		@apply bg-grey-light border-[2px] border-white text-grey-text-light px-3 py-3 rounded-[3px] mb-1 w-full font-roboto font-bold outline-none focus:border-orange-highlight focus:shadow-md focus:ring-0 transition-all duration-500 placeholder:text-black placeholder:font-medium text-[14px];
	}

}

body {
	margin: 0;
	font-size: 16px;
	font-family: 'Roboto';
}

a {
	text-decoration: none;
}

ul {
	list-style-type: none;
}

ul li::before {
	content: '\25A0';
	font-size: 18px;
	color: #ee5340;
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

button {
	outline: none;
}

button:hover {
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #c2c9d2;
}

